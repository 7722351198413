<script>

import PageHeader from "../../../components/page-header.vue";
import CustomTable from "@/components/tables/table.vue";
import PreviewModal from "./components/PreviewModal.vue";
import AddCodeModal from "./components/AddCodeModal.vue";
import SeeCodesModal from "./components/SeeCodesModal.vue";
import skeleton from "../../../components/custom/skeleton.vue";

import Swal from "sweetalert2";

import SwiperCore, {Navigation, Pagination, Thumbs} from "swiper";
import {Swiper, SwiperSlide} from "swiper/vue";
import "swiper/swiper-bundle.css";
import CounterpartyApi from "@/api/counterparty/CounterpartyApi";

SwiperCore.use([Thumbs, Pagination, Navigation]);

import axios from "axios";
import LoadingTypes from "@/core/order/LoadingTypes";

export default {
  page: {
    title: "Invoice List",
  },
  data() {
    return {
      title: "Applications",
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Applications List",
          active: true,
        },
      ],
      forwarders: [],
      forwarders_has_been_loaded: false,

      isLoading: false,

      currentApplication: {
        data: {}
      },

      getUpdate: false,
      applicationWidgets: [],

      table: {
        url: process.env.VUE_APP_ORDER_URL + '/code/application/list/',
        headers: [
          {
            label: 'NUMBER',
            field: 'number',
            align: 'center',
          },
          {
            label: 'Forwarder',
            field: 'forwarder',
            align: 'center',
            searchType: 'select',
            searchOptions: [],
            excel_data: (app) => app.forwarder ? app.forwarder.name ? app.forwarder.name : '' : ''
          },
          {
            label: 'Loading type',
            field: 'loading_type',
            align: 'center',
            searchType: 'select',
            searchOptions: LoadingTypes,
            excel_data: (app) => app.loading_type
                ? app.loading_type
                    .split('_')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ')
                : ''
          },
          {
            label: 'Orders',
            field: 'orders',
            align: 'center',
          },
          {
            label: 'Departure',
            field: 'departure',
            align: 'center',
            excel_data: (app) => `${app.departure.name} (${app.departure.code})`
          },
          {
            label: 'Destination',
            field: 'destination',
            align: 'center',
            excel_data: (app) => `${app.destination.name} (${app.destination.code})`
          },
          {
            label: 'File',
            field: 'file',
            align: 'center',
            searchable: false,
            excel_data: (app) => app.file
                ? {
                  text: 'FILE',
                  hyperlink: process.env.VUE_APP_ORDER_URL + app.file // Actual link URL
                }
                : ''
          },
          {
            label: 'Date',
            field: 'date',
            align: 'center',
          },
          {
            label: 'Code Price',
            field: 'code_price',
            align: 'center',
            searchable: false,
          },
          {
            label: 'Quantity',
            field: 'quantity',
            align: 'center',
          },
          {
            label: 'Comment',
            field: 'comment',
            align: 'center',
          },
          {
            label: 'Manager',
            field: 'user',
            align: 'center',
            excel_data: (app) => app.user ? app.user.username ? app.user.username : '' : ''
          },
          {
            label: 'actions',
            field: 'actions',
          },
        ],
        pagination: {
          perPage: 10,
        },
      },

      ORDER_CHILD_TYPE: {
        container_order: {
          path: 'orders_container_detail',
          name: 'Container Order'
        },
        wagon_order: {
          path: 'orders_wagon_detail',
          name: 'Wagon Order'
        },
        wagon_empty_order: {
          path: 'orders_empty_wagon_detail',
          name: 'Empty Wagon Order'
        },
      }
    };
  },
  components: {
    PageHeader,
    // FileTextIcon,
    // CheckSquareIcon,
    // ClockIcon,
    // XOctagonIcon,
    PreviewModal,
    AddCodeModal,
    SeeCodesModal,
    Swiper,
    SwiperSlide,
    CustomTable,
    skeleton,
  },
  methods: {
    async getApplicationWidgetsData() {
      try {
        let request = await axios.get(`/code/application/statistic/`)
        this.applicationWidgets = await request.data
      } catch {
        this.applicationWidgets = []
      }
    },

    async downloadFile(url_without_base) {
      let url = process.env.VUE_APP_ORDER_URL + url_without_base
      fetch(url)
          .then(resp => resp.blob())
          .then(blobobject => {
            const blob = window.URL.createObjectURL(blobobject);
            const anchor = document.createElement('a');
            anchor.style.display = 'none';
            anchor.href = blob;
            anchor.download = url.toString().split('/')[url.toString().split('/').length - 1];
            document.body.appendChild(anchor);
            anchor.click();
            window.URL.revokeObjectURL(blob);
          })
          .catch(() => alert('An error in downloading the file sorry'));
    },

    async confirmApplicationDelete(id, appNumber) {
      await Swal.fire({
        position: "center",
        icon: "error",
        title: `You are about to delete application ${appNumber}`,
        text: 'Deleting your application will remove all of its information from our database.',
        showDenyButton: true,
        showConfirmButton: true,
        confirmButtonText: 'Yes, Delete It',
        denyButtonText: 'Cancel',
        cancelButtonColor: 'transparent',
        focusConfirm: false,
        inputLabel: `Please type Application${appNumber} to confirm`,
        input: 'email',
        inputPlaceholder: `Application${appNumber}`,
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value === 'Application' + appNumber) {
              resolve(this.deleteApplication(id))
            } else {
              resolve('Application number did not match :)')
            }
          })
        }
      });
    },

    async deleteApplication(id) {
      await axios.delete(`/code/application/delete/${id}`).then(async () => {
        await this.$refs.table.getData()
        await Swal.fire({
          position: "center",
          icon: "success",
          title: "Application Deleted",
          text: "Application has been deleted successfully",
          showConfirmButton: true,
          confirmButtonText: 'Ok',
          cancelButtonColor: 'transparent',
          focusConfirm: false,
        });
      })
    },

    showAddCodeModal(application) {
      this.currentApplication.data = application
      document.getElementById('addCodeTriggerBtn').click()
    },
    showSeeCodesModal(application) {
      this.currentApplication.data = {id: application.id}
      document.getElementById('seeCodesTriggerBtn').click()
    },

    async getForwarders() {
      let api = new CounterpartyApi()
      let response = await api.getCounterparties('?is_used_for_code=true')
      this.forwarders = response.results.map(forwarder => {
        return {
          value: forwarder.id,
          label: forwarder.name
        }
      })
      this.forwarders_has_been_loaded = true
      this.updateHeaders()
    },

    updateHeaders() {
      this.table.headers = this.table.headers.map(header => {
        if (header.field === 'forwarder') {
          let forwarders = this.forwarders
          forwarders.unshift({value: '', label: ''})
          return {
            ...header,
            searchOptions: forwarders
          };
        }
        return header;
      });
    }
  },
  async created() {
    this.isLoading = true;
    await this.getForwarders()
    await this.getApplicationWidgetsData();
    this.isLoading = false;
  }
};
</script>
<template>
  <PageHeader :title="title" :items="items"/>
  <div class="row p-0" v-if="isLoading">
    <div class="col-12 col-sm-6 col-md-3">
      <div class="card">
        <div class="card-body pb-0">
          <div class="d-flex justify-content-between align-items-center">

            <div style="min-width: 65%">
              <h2 class="mb-3" style="max-width: 55%">
                <skeleton/>
              </h2>
              <p class="text-muted">
                <skeleton/>
              </p>
            </div>

            <div class="text-end" style="min-width: 10px">
              <h5 class="mb-3">
                <skeleton/>
              </h5>
              <p class="text-muted">
                <skeleton/>
              </p>
            </div>

          </div>

        </div>
        <div class="progress pt-0 animated-progess rounded-bottom rounded-0" style="height: 4px">
          <div
              class="progress-bar rounded-0 bg-success" role="progressbar" style="width: 100%"
              aria-valuenow="100"
              aria-valuemin="0" aria-valuemax="100">
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-3">
      <div class="card">
        <div class="card-body pb-0">
          <div class="d-flex justify-content-between align-items-center">

            <div style="min-width: 65%">
              <h2 class="mb-3" style="max-width: 55%">
                <skeleton/>
              </h2>
              <p class="text-muted">
                <skeleton/>
              </p>
            </div>

            <div class="text-end" style="min-width: 10px">
              <h5 class="mb-3">
                <skeleton/>
              </h5>
              <p class="text-muted">
                <skeleton/>
              </p>
            </div>

          </div>

        </div>
        <div class="progress pt-0 animated-progess rounded-bottom rounded-0" style="height: 4px">
          <div
              class="progress-bar rounded-0 bg-success" role="progressbar" style="width: 100%"
              aria-valuenow="100"
              aria-valuemin="0" aria-valuemax="100">
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-3">
      <div class="card">
        <div class="card-body pb-0">
          <div class="d-flex justify-content-between align-items-center">

            <div style="min-width: 65%">
              <h2 class="mb-3" style="max-width: 55%">
                <skeleton/>
              </h2>
              <p class="text-muted">
                <skeleton/>
              </p>
            </div>

            <div class="text-end" style="min-width: 10px">
              <h5 class="mb-3">
                <skeleton/>
              </h5>
              <p class="text-muted">
                <skeleton/>
              </p>
            </div>

          </div>

        </div>
        <div class="progress pt-0 animated-progess rounded-bottom rounded-0" style="height: 4px">
          <div
              class="progress-bar rounded-0 bg-success" role="progressbar" style="width: 100%"
              aria-valuenow="100"
              aria-valuemin="0" aria-valuemax="100">
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-3">
      <div class="card">
        <div class="card-body pb-0">
          <div class="d-flex justify-content-between align-items-center">

            <div style="min-width: 65%">
              <h2 class="mb-3" style="max-width: 55%">
                <skeleton/>
              </h2>
              <p class="text-muted">
                <skeleton/>
              </p>
            </div>

            <div class="text-end" style="min-width: 10px">
              <h5 class="mb-3">
                <skeleton/>
              </h5>
              <p class="text-muted">
                <skeleton/>
              </p>
            </div>

          </div>

        </div>
        <div class="progress pt-0 animated-progess rounded-bottom rounded-0" style="height: 4px">
          <div
              class="progress-bar rounded-0 bg-success" role="progressbar" style="width: 100%"
              aria-valuenow="100"
              aria-valuemin="0" aria-valuemax="100">
          </div>
        </div>
      </div>
    </div>
  </div>
  <swiper
      class="default-swiper rounded"
      :loop="false"
      :grabCursor="true"
      :slidesPerView="4"
      :spaceBetween="15"
      v-if="!isLoading"
  >
    <swiper-slide v-for="widget in applicationWidgets" :key="widget.id">
      <div class="card">
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-center mb-2">
            <div>
              <h5 class="fs-15 fw-semibold text-capitalize">{{ widget['forwarder__name'] }}</h5>
            </div>

          </div>
          <div class="mt-auto">
            <div class="d-flex mb-2">
              <div class="flex-grow-1">
                <div>Applications</div>
              </div>
              <div class="flex-shrink-0">
                <div>
                  <i class="ri-list-check align-bottom me-1 text-muted"></i>
                  {{ widget.filled }}/{{ widget.filled + widget.not_filled }}
                </div>
              </div>
            </div>
            <div class="progress progress-sm animated-progess">
              <div class="progress-bar bg-success" role="progressbar"
                   :aria-valuenow="widget.filled" aria-valuemin="0"
                   aria-valuemax="100"
                   :style="{width: ((widget.filled) / (widget.filled + widget.not_filled)) *100 + '%'}"></div>
            </div>
          </div>
        </div>
      </div>
    </swiper-slide>
  </swiper>

  <div class="mb-4">
    <CustomTable
        :url="table.url"
        name="APPLICATION TABLE"
        id="application_table"
        :headers="table.headers"
        :selectable="true"
        :searchable="true"
        :getUpdate="getUpdate"
        ref="table"
        v-if="forwarders_has_been_loaded"
    >

      <template #top-right>
        <router-link :to="{name: 'application_create'}">
          <b-button variant="success" class="waves-effect waves-light">Create application</b-button>
        </router-link>
      </template>

      <template v-slot:number="slotProps">
        <h5 @click="this.currentApplication.data = slotProps.row" data-bs-toggle="modal"
            data-bs-target="#previewModal" class="fw-medium link-primary"
            style="cursor: pointer"
        >
        <span class="badge badge-soft-primary">
          {{ slotProps.row.number }}
        </span>
        </h5>
      </template>

      <template v-slot:forwarder="slotProps">
        {{ slotProps.row.forwarder.name }}
      </template>

      <template v-slot:loading_type="{row: application}">
       <span v-if="application.loading_type">
        {{ application.loading_type.replace("_", " ") }}
       </span>
        <span v-else>--</span>
      </template>

      <template v-slot:orders="{row: application}">
        <div v-if="application.orders" class="d-flex flex-wrap gap-2 justify-content-center">
          <template v-for="order in (application.orders || [])" :key="`order_${order}`">
            <router-link :to="{
              name: ORDER_CHILD_TYPE[order.child_type].path,
              params: { id: order.order_number }
            }" v-if="ORDER_CHILD_TYPE[order.child_type]"
                         class="badge"
                         :class="{
              'badge-soft-secondary': this.$route.query.orders ? !order.order_number.toString().includes(this.$route.query.orders) : true,
              'bg-secondary': this.$route.query.orders ? order.order_number.toString().includes(this.$route.query.orders) : false,
                         }"
                         v-b-tooltip.hover :title="ORDER_CHILD_TYPE[order.child_type].name"
            >
              {{ order.order_number }}
            </router-link>
          </template>
        </div>
        <span v-else>--</span>
      </template>

      <template v-slot:departure="slotProps">
        {{ slotProps.row.departure.name }}
      </template>

      <template v-slot:destination="slotProps">
        {{ slotProps.row.destination.name }}
      </template>

      <template v-slot:file="slotProps">
        <span v-if="slotProps.row.file === null" class="text-danger">-</span>

        <span v-if="slotProps.row.file !== null" @click="downloadFile(slotProps.row.file)" class="text-danger">
        <font-awesome-icon
            icon="fa-solid fa-file-zipper"
            class="c_icon_hoverable text-secondary"
        />
      </span>
      </template>

      <template v-slot:quantity="slotProps">
        <h6 class="my-0" @click="showSeeCodesModal(slotProps.row)">
        <span class="badge" :class="{
          'badge-outline-danger': slotProps.row.code_count > slotProps.row.quantity,
          'badge-outline-success': slotProps.row.code_count === slotProps.row.quantity,
          'badge-outline-warning': slotProps.row.code_count < slotProps.row.quantity,
        }">
          {{ slotProps.row.code_count }}/{{ slotProps.row.quantity }}
        </span>
        </h6>
      </template>

      <template v-slot:date="slotProps">
        <span class="link-primary fw-medium">{{ slotProps.row.date }}</span>
      </template>

      <template v-slot:code_price="{row: application }">
        {{ parseFloat(application.code_price || 0).toLocaleString() }} *
        {{ parseFloat(application.code_count || 0).toLocaleString() }}
        = {{ (parseFloat(application.code_price || 0) * parseFloat(application.code_count || 0)).toLocaleString() }}
      </template>

      <template v-slot:comment="slotProps">
        <div class="w-100">
          <VTooltip>
                <span class="d-inline-block text-truncate" style="max-width: 100px;">
                    {{ slotProps.row.comment }}
                </span>
            <template #popper>
              {{ slotProps.row.comment }}
            </template>
          </VTooltip>
        </div>
      </template>

      <template v-slot:user="{row: application}">
        <template v-if="application.user">
          <router-link :to="{name: 'user_profile', params: {slug: application.user.slug}}">
            <div class="d-flex align-items-center">
              <div class="avatar-xxs flex-shrink-0 me-1">
        <span class="avatar-title bg-light text-primary rounded-circle">
          {{ application.user.username[0].toUpperCase() }}
        </span>
              </div>
              <span>{{ application.user.username }}</span>
            </div>
          </router-link>
        </template>
        <span v-else>--</span>
      </template>

      <template v-slot:actions="slotProps">

        <b-button-group class="mt-4 mt-md-0" role="group" size="sm" aria-label="Basic example">
          <b-button v-if="slotProps.row.code_count < slotProps.row.quantity"
                    @click="showAddCodeModal(slotProps.row)" variant="light">
            <i class="ri-add-fill align-bottom fs-6"></i></b-button>
          <b-button variant="light" class="p-0">
            <div class="px-1">
              <router-link class="text-dark"
                           :to="{ name: 'application_update', params: { id: slotProps.row.id } }">
                <i class="ri-pencil-fill align-bottom px-1"></i>
              </router-link>
            </div>
          </b-button>
          <b-button variant="light" @click="confirmApplicationDelete(slotProps.row.id, slotProps.row.number)">
            <i class="ri-delete-bin-7-fill align-bottom text-danger"></i>
          </b-button>
        </b-button-group>

      </template>

    </CustomTable>
  </div>

  <PreviewModal :application="currentApplication.data"/>

  <AddCodeModal :application="currentApplication.data" @update="this.$refs.table.getData()"/>
  <SeeCodesModal :application="currentApplication.data"/>

</template>